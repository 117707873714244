// Constructor
var Header = function () {
  var header = $('.header');
  var body = $('body');
  var menuOpen = $('.header__hamburguer');
  var menuClose = $('.header__nav__close');
  var arrowLeft = $('.-js-arrow-left')
  var arrowRight = $('.-js-arrow-right')
  var languages = $('.header__lang a');
  var sections = $('.section');
  var slides = $('.home__section-3__slide');

  var activeSection = 1;
  var activeSlide = 1;
  var scrolledDistance = 0;

  // $(window).on('scroll', _.debounce(function(e) {
  //   if (scrolledDistance > 0) {      
  //     if (activeSection != 3) {
  //       scrollSection('down');
  //     } else {
  //       if (activeSlide >= 1 && activeSlide < slides.length) {
  //         scrollSlide('down');
  //       } else {
  //         scrollSection('down');
  //       }
  //     }
  //   } else {
  //     if (activeSection != 3) {
  //       scrollSection('up');
  //     } else {
  //       if (activeSlide <= slides.length && activeSlide > 1) {
  //         scrollSlide('up');
  //       } else {
  //         scrollSection('up');
  //       }
  //     }
  //   }

  //   scrolledDistance = 0;
    
  // }, 200));

  // $(window).on('mousewheel', _.debounce(function (e) {
  //   scrolledDistance = e.originalEvent.deltaY;
  // }, 200, { 'leading': true }));

  // $(window).on('touchstart', _.debounce(function (e) {
  //   console.log('touchstarts');
  //   var initialX = e.originalEvent.touches[0].clientX;
  //   var initialY = e.originalEvent.touches[0].clientY;
  //   var targetIsArrow = false;

    
  //   if (e.target.classList.contains('-js-arrow-right') || e.target.classList.contains('-js-arrow-left')) {
  //     targetIsArrow = true;
  //   }
    
  //   if (e.target.parentNode.classList.contains('-js-arrow-right') || e.target.parentNode.classList.contains('-js-arrow-left')) {
  //     targetIsArrow = true;
  //   }
    
  //   console.log('touchstart event', e);
  //   console.log('targetIsArrow', targetIsArrow);

  //   if (!targetIsArrow) {

  //     $(window).on('touchend', function touchEndHandler(e) {
  //       console.log('touchend');
  //       var currentX = e.originalEvent.changedTouches[0].clientX;
  //       var currentY = e.originalEvent.changedTouches[0].clientY;
      
  //       var diffX = initialX - currentX;
  //       var diffY = initialY - currentY;

  //       console.log('dirs', {
  //         initialX,
  //         initialY,
  //         currentX,
  //         currentY,
  //         diffX,
  //         diffY
  //       });

  //       if (Math.abs(diffX) > Math.abs(diffY)) {
  //         // sliding horizontally
  //         if (diffX > 80) {
  //           scrolledDistance = 100;
  //           if (activeSection === 3 && activeSlide < slides.length) {
  //             document.scrollingElement.scrollTop = document.scrollingElement.scrollTop + 100;
  //           }
  //         } else {
  //           // swiped right
  //           scrolledDistance = -100;
  //           if (activeSection === 3 && activeSlide > 1) {
  //             document.scrollingElement.scrollTop = document.scrollingElement.scrollTop - 100;
  //           }
  //         }  
  //       } else {
  //         // sliding vertically
  //         if (diffY > 80) {
  //           scrolledDistance = 100;
  //         } else {
  //           // swiped down
  //           scrolledDistance = -100;
  //         }  
  //       }
  //     });
  //   }    
  // }, 200, { 'leading': true }));

  // function scrollSection(direction) {
  //   if (direction === 'down') {
  //     if(activeSection === sections.length) return
  //     updateActiveSection('add')
  //   } else {
  //     if(activeSection === 1) return
  //     updateActiveSection('less');
  //   }
  
  //   updateSections();
  // }
  
  function scrollSlide(direction) {
    if (direction === 'down' || direction === 'right') {
      updateActiveSlide('add')
    } else {
      updateActiveSlide('less');
    }
    
    updateSlides();
  }

  // function updateActiveSection(action) {
  //   activeSection = action === 'add' && activeSection < sections.length ? activeSection + 1 : activeSection - 1;
  // }

  function updateActiveSlide(action) {
    activeSlide = action === 'add' && activeSlide < slides.length ? activeSlide + 1 : activeSlide - 1;
  }

  // function updateSections() {
  //   sections.each(function () {
  //     if ($(this).data('section') < activeSection) {
  //       $(this).addClass('prev-section')
  //       $(this).removeClass('next-section')
  //     }

  //     if ($(this).data('section') > activeSection) {
  //       $(this).addClass('next-section')
  //       $(this).removeClass('prev-section')
  //     }

  //     if ($(this).data('section') === activeSection) {
  //       $(this).removeClass('next-section')
  //       $(this).removeClass('prev-section')
  //     }
  //   });
  // }

  function updateSlides() {
    slides.each(function () {
      if ($(this).data('slide') < activeSlide) {
        $(this).addClass('prev-slide')
        $(this).removeClass('next-slide')
      }

      if ($(this).data('slide') > activeSlide) {
        $(this).addClass('next-slide')
        $(this).removeClass('prev-slide')
      }

      if ($(this).data('slide') === activeSlide) {
        $(this).removeClass('next-slide')
        $(this).removeClass('prev-slide')
      }
    });
  }

  if (window.location.pathname.length > 1) {
    $(languages[1]).addClass('-active');
  } else {
    $(languages[0]).addClass('-active');
  }

  // function getScrollDirection(e) {
  //   return e.originalEvent.deltaY > 0 ? 'down' : 'up'
  // }

  menuOpen.on('click', function () {
    header.addClass('-open');
    body.addClass('-hideOverflow');
  });

  menuClose.on('click', function () {
    header.removeClass('-open');
    body.removeClass('-hideOverflow');
  });

  arrowLeft.on('click', function () {
    scrollSlide('up')
  })

  arrowRight.on('click', function () {
    scrollSlide('down')
  })

  var targets = $('[data-target]');
  var contents = $('[data-content]');
  var contentWrapper = $('.home__section-4__text-group');

  $(targets[0]).addClass('-active');
  $(contents[0]).addClass('-active');

  contentWrapper.height($(contents[0]).height());

  targets.on('click', function () {
    targets.removeClass('-active')
    contents.removeClass('-active')

    var target = $(this).data('target');
    var targettedContent = contents.filter(function () {
      return $(this).data('content') == target;
    })

    $(this).addClass('-active');
    targettedContent.addClass('-active');
    contentWrapper.height(targettedContent.height());
  });
};

module.exports = Header;